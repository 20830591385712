import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight, faCheck, faColumns, faEyeSlash,
    faInfoCircle,
    faSearch, faSort, faSortAmountDown, faSortAmountDownAlt, faTimes
} from '@fortawesome/free-solid-svg-icons';
import Loader from "assets/svg-loaders/bars.svg";
import cn from 'classnames';


const Table = (props) => {

    const {
        name,
        noPagination = false,
        noSearch = false,
        noHiddenColumns = false,
        noSort = false,
        columns = [],
        data = {},
        loading = false,
        page,
        perPage,
        goToPage,
        filters,
        sort,
        multiSort = [],
        bulkActions = null
    } = props
    const {setPage, setPerPage, setGoToPage, setFilters, setSort, setMultiSort, clearFilters} = props

    //  local state
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [showColumns, setShowColumns] = useState(false);
    const [showMultiSort, setShowMultiSort] = useState(false);
    const [localMultiSort, setLocalMultiSort] = useState([]);
    const [multiSortApplied, setMultiSortApplied] = useState(true);

    const rows = data.data || [];
    const total = data.total || '...';
    const last_page = data.last_page || 0;

    let pages;
    let showFirst, showLast, prev, next;

    if (page > 5 && last_page > 9) {
        if (page + 5 > last_page)
            pages = [...Array(last_page + 1).keys()].slice(last_page - 8)
        else
            pages = [...Array(page + 5).keys()].slice(page - 4)
        showFirst = true;
    } else {
        if (last_page < 9)
            pages = [...Array(last_page + 1).keys()].slice(1)
        else
            pages = [...Array(10).keys()].slice(1)
    }

    showLast = (page + 4) < last_page;
    prev = page > 1;
    next = page < last_page;

    const showingFrom = ((page - 1) * perPage) + 1;
    const showingTo = (page * perPage) > data.total ? data.total : (page * perPage);


    // handlers
    const updateSort = (field) => {
        if (sort && sort.sortBy === field) {
            sort.sortDir === 'ASC' ? setSort({sortBy: field, sortDir: 'DESC'}) : setSort(null);
        } else {
            setSort({sortBy: field, sortDir: 'ASC'});
        }
    }

    const updateMultiSort = (name, dir = "ASC") => {
        const index = localMultiSort.findIndex(s => s.name === name);

        if (index > -1) {
            setLocalMultiSort([
                ...localMultiSort.slice(0, index),
                {name, dir},
                ...localMultiSort.slice(index + 1),
            ]);
        } else {
            setLocalMultiSort([...localMultiSort, {name, dir}]);
        }

        setMultiSortApplied(false);
    }

    const deleteMultiSort = (index) => {
        setLocalMultiSort([
            ...localMultiSort.slice(0, index),
            ...localMultiSort.slice(index + 1),
        ]);

        setMultiSortApplied(false);
    }

    const applyMultiSort = () => {
        setShowMultiSort(false);
        setMultiSort(localMultiSort);
        setMultiSortApplied(true);
    }

    const clearMultiSort = () => {
        setShowMultiSort(false);
        setLocalMultiSort([]);
        setMultiSort([]);
        setMultiSortApplied(true);
    }

    const updateHiddenColumns = (name) => {
        const index = hiddenColumns.indexOf(name);
        if (index === -1)
            setHiddenColumns([...hiddenColumns, name]);
        else
            setHiddenColumns([
                ...hiddenColumns.slice(0, index),
                ...hiddenColumns.slice(index + 1)
            ]);
    }

    const hideAllColumns = () => {
        if (hiddenColumns.length < columns.length)
            setHiddenColumns([...columns.map(c => c.field)]);
        else
            setHiddenColumns([])
    }

    // helpers
    const getValue = (v) => !v || v === 'NULL' ? '' : v;

    const renderSort = (field) => {
        if (sort && sort.sortBy === field) {
            return sort.sortDir === 'ASC' ? <FontAwesomeIcon icon={faSortAmountDownAlt}/> :
                <FontAwesomeIcon icon={faSortAmountDown}/>;
        }

        return <FontAwesomeIcon icon={faSort}/>;
    }

    const renderFilter = (item) => {
        return (
            item.filter.type === 'string' ? <div className="flex flex-row items-center space-x-1">
                <FontAwesomeIcon icon={faSearch}/>
                <input type="text"
                       autoComplete="off"
                       value={filters[item.field] || ''}
                       onChange={e => setFilters(item.field, e.target.value)}
                       name={item.field}
                       className="border-b w-20 text-center outline-none"/>
            </div> : <div className="flex flex-row items-center space-x-1">
                <FontAwesomeIcon icon={faSearch}/>
                <select className="border-b w-28 text-center outline-none">
                    <option>select type</option>
                </select>
            </div>
        );
    };

    return (
        <div className="relative flex-shrink-0 flex-grow-0 flex flex-col space-y-5"
             style={{width: "98%"}}>
            <div className="flex flex-row justify-between items-center space-x-3 flex-shrink-0 flex-grow-0 w-full">
                <div className="flex flex-row items-center space-x-3">
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <h1>{name}</h1>
                    <span className="leading-7 px-2 rounded bg-blue-500 text-center text-gray-200">{total}</span>
                </div>
                {Object.keys(filters).length > 0 && <div onClick={clearFilters}
                                                         className='flex flex-row  items-center space-x-2 text-sm cursor-pointer'>
                    <span
                        className="h-4 w-4 leading-4 rounded-full bg-blue-500 text-center text-gray-200">{Object.keys(filters).length}</span>
                    <span className="text-blue-700">Clear Filters</span>
                </div>}
                {multiSort.length > 0 &&
                    <div onClick={clearMultiSort}
                         className='flex flex-row  items-center space-x-2 text-sm cursor-pointer'>
                    <span
                        className="h-4 w-4 leading-4 rounded-full bg-blue-500 text-center text-gray-200">{multiSort.length}</span>
                        <span className="text-blue-700">Clear Multiple Sort</span>
                    </div>}
                {bulkActions && bulkActions}
                <div className="flex flex-row items-center space-x-10">
                    {!noSort && <div className="relative" title="Multiple Sort">
                        <span onClick={() => {
                            setShowMultiSort(!showMultiSort);
                            setShowColumns(false);
                        }} className="cursor-pointer text-blue-600">
                            <FontAwesomeIcon size="lg" icon={faSort}/>
                        </span>
                        {showMultiSort && <div
                            className="w-70 border bg-white z-20 absolute top-0 right-8 text-sm space-y-3 py-4 px-2 overflow-auto"
                            style={{maxHeight: '300px'}}>
                            <div className="flex flex-row justify-between items-center space-x-4">
                                <select className="border" value="" onChange={e => updateMultiSort(e.target.value)}>
                                    <option value="">Select Column</option>
                                    {columns.map((item, i) => localMultiSort.findIndex(s => s.name === item.field) === -1 &&
                                        <option key={`multi-sort-${i}`} value={item.field}>{item.field}</option>)}
                                </select>
                            </div>
                            {localMultiSort.map((item, i) => (
                                <div key={`hColumn-k-${i}`}
                                     className="flex flex-row justify-between items-center space-x-4">
                                    <div className="flex flex-row items-center space-x-2">
                                        <FontAwesomeIcon onClick={() => deleteMultiSort(i)}
                                                         className="cursor-pointer text-red-400" size="sm"
                                                         icon={faTimes}/>
                                        <span>{item.name}</span>
                                    </div>
                                    <select className="border" value={item.dir}
                                            onChange={e => updateMultiSort(item.name, e.target.value)}>
                                        <option value="ASC">Ascending</option>
                                        <option value="DESC">Descending</option>
                                    </select>
                                </div>
                            ))}
                            {!multiSortApplied && <div className="flex flex-row justify-center">
                                <button onClick={applyMultiSort}
                                        className="bg-blue-500 rounded text-xs text-white py-1 px-2"><FontAwesomeIcon
                                    icon={faCheck}/> Apply
                                </button>
                            </div>}
                        </div>}
                    </div>}
                    {!noHiddenColumns && <div className="relative" title="Show / Hide Columns">
                        <span onClick={() => {
                            setShowColumns(!showColumns);
                            setShowMultiSort(false);
                        }} className="cursor-pointer text-blue-600">
                            <FontAwesomeIcon size="lg" icon={faColumns}/>
                        </span>
                        {showColumns && <div
                            className="w-48 border bg-white z-20 absolute top-0 right-8 text-sm space-y-1 py-4 px-2 overflow-auto"
                            style={{maxHeight: '300px'}}>
                            <div className=" flex flex-row items-center space-x-2">
                                <input
                                    type="checkbox"
                                    onChange={hideAllColumns}
                                    checked={!hiddenColumns.length}/>
                                <span>All</span>
                            </div>
                            {columns.map((item, i) => (
                                <div key={`hColumn-k-${i}`} className=" flex flex-row items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        onChange={() => updateHiddenColumns(item.field)}
                                        checked={hiddenColumns.indexOf(item.field) === -1}/>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>}
                    </div>}
                    {!noSearch && <div className="flex flex-row items-center mr-10 space-x-2">
                        <FontAwesomeIcon size="lg" icon={faSearch}/>
                        <input type="text"
                               name="wildcard"
                               value={filters.wildcard || ''}
                               onChange={e => setFilters('wildcard', e.target.value)}
                               className="rounded p-2 border-b"
                               placeholder="search"/>
                    </div>}
                </div>
            </div>
            {loading &&
                <div className="absolute bottom-0 w-full bg-blue-200 flex justify-center items-center opacity-60 z-10"
                     style={{height: "490px"}}>
                    <img alt="loading..." src={Loader}/>
                </div>}
            <div className="bg-white relative text-sm text-gray-700 w-full overflow-x-scroll overflow-y-hidden"
                 style={{height: "500px"}}>
                <table className="text-center h-full w-full">
                    <thead className="block">
                    <tr className="border-b flex flex-row">
                        {columns.map((item, i) => hiddenColumns.indexOf(item.field) !== -1 ? null : (
                            <td key={`column-k-${i}`} className="p-4 w-40">
                                <div className="flex flex-col items-center space-y-2">
                                    <div className="flex flex-row space-x-2">
                                        <span className="font-bold">{item.name}</span>
                                        {!noSort && <span title="Sort" onClick={() => updateSort(item.field)}
                                              className={cn('cursor-pointer',
                                                  sort && sort.sortBy === item.field && 'text-blue-500')}>
                                            {renderSort(item.field)}
                                        </span>}
                                        {!noHiddenColumns && <span title="Hide" className="cursor-pointer"
                                              onClick={() => setHiddenColumns([...hiddenColumns, item.field])}>
                                            <FontAwesomeIcon className="text-yellow-600" size="xs" icon={faEyeSlash}/>
                                        </span>}
                                    </div>
                                    {item.filter && renderFilter(item)}
                                </div>
                            </td>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="overflow-scroll block text-xs" style={{height: "430px"}}>
                    {rows.map(row => (
                        <tr key={`row-k-${row.id}`} className="flex flex-row border-b hover:bg-gray-200">
                            {columns.map((item, i) => hiddenColumns.indexOf(item.field) !== -1 ? null : (
                                <td key={`row-col-k-${row.id}-${i}`} className="p-4 w-40 break-words">
                                    {item.render ? item.render(row) : getValue(row[item.field])}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {!noPagination && <div className="flex flex-row justify-between p-3 bg-white text-sm text-gray-700">
                <div className="flex flex-row items-center space-x-4">
                    <span>Show</span>
                    <select value={perPage} className="border-b" onChange={e => {
                        setPage(1);
                        setPerPage(e.target.value);
                    }}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <span>Rows</span>
                    <span className="ml-10">
                        {showingFrom} - {showingTo}
                    </span>
                </div>

                <div className="flex flex-row items-center space-x-2">
                    {showFirst &&
                        <span onClick={() => setPage(1)} title="First Page" className="cursor-pointer"><FontAwesomeIcon
                            icon={faAngleDoubleLeft}/></span>}
                    <span onClick={() => setPage(page > 1 ? page - 1 : 1)} title="Previous Page"
                          className={cn(!prev ? 'text-gray-400' : 'cursor-pointer')}><FontAwesomeIcon
                        icon={faAngleLeft}/></span>
                    {pages.map(p => (<span onClick={() => setPage(p)} title={`Page ${p}`} key={`page-k-${p}`}
                                           className={cn(page === p ? 'text-gray-400' : 'cursor-pointer hover:underline')}>{p}</span>))}
                    <span onClick={() => setPage(page < last_page ? page + 1 : last_page)} title="Next Page"
                          className={cn(!next ? 'text-gray-400' : 'cursor-pointer')}><FontAwesomeIcon
                        icon={faAngleRight}/></span>
                    {showLast && <span onClick={() => setPage(last_page)} title="Last Page"
                                       className="cursor-pointer"><FontAwesomeIcon
                        icon={faAngleDoubleRight}/></span>}
                </div>
                <div className="flex flex-row items-center space-x-2">
                    <span className="mr-2">{last_page} Pages</span>
                    <input value={goToPage} onChange={e => setGoToPage(e.target.value)}
                           className="border-b outline-none w-10"
                           type="text" name="page" placeholder="Page"/>
                    <span onClick={() => setPage(parseInt(goToPage) > last_page ? last_page : parseInt(goToPage))}
                          className="bg-indigo-50 py-1 px-2 cursor-pointer">Go</span>
                </div>
            </div>}
        </div>
    );
}

export default Table;
