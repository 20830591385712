import {all} from 'redux-saga/effects';
import registration from './registration';
import users from './users';
import data from './data';

/** Root Saga */
export default function* root() {
    yield all([
        ...registration,
        ...users,
        ...data,
    ]);
}
