import {applyMiddleware, compose, createStore} from 'redux';
import Reactotron from './reactotron';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['registration', 'users', 'data']
};

const storeConfig = (rootReducer, rootSaga) => {

    let middlewares = [];
    let enhancers = [];

    const sagaMonitor = Reactotron.createSagaMonitor();
    const sagaMiddleware = createSagaMiddleware({sagaMonitor});

    middlewares.push(sagaMiddleware);

    enhancers.push(applyMiddleware(...middlewares));

    enhancers.push(Reactotron.createEnhancer());

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = createStore(persistedReducer, compose(...enhancers)); // you can add preLoaded state as second argument

    const persistor = persistStore(store);

    sagaMiddleware.run(rootSaga);

    return {store, persistor}
};

export default storeConfig;
