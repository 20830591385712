// Users Store
export const GET_USERS = 'users/get_users';
export const GET_USERS_SUCCESS = 'users/get_users_success';
export const GET_USERS_FAILURE = 'users/get_users_failure';
export const CREATE_USER = 'users/create_user';
export const CREATE_USER_SUCCESS = 'users/create_user_success';
export const CREATE_USER_FAILURE = 'users/create_user_failure';
export const UPDATE_USER = 'users/update_user';
export const UPDATE_USER_SUCCESS = 'users/update_user_success';
export const UPDATE_USER_FAILURE = 'users/update_user_failure';
export const DELETE_USER = 'users/delete_user';
export const DELETE_USER_SUCCESS = 'users/delete_user_success';
export const DELETE_USER_FAILURE = 'users/delete_user_failure';
export const RESET_CREATE_USER = 'users/reset_create_user';
export const RESET_DELETE_USER = 'users/reset_delete_user';

// Initial State
const INITIAL_STATE = {
    data: {},
    loading: false,
    createUser: {
        processing: false,
        success: false,
        errors: {}
    },
    deleteUser: {
        processing: false,
        success: false,
        error: false,
    }
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_USERS:
            return {...state, loading: true};
        case GET_USERS_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case GET_USERS_FAILURE:
            return {...state, loading: false, data: {}};
        case CREATE_USER: // Both update and create are similar, so we use same state
        case UPDATE_USER:
            return {...state, createUser: {...state.createUser, processing: true, success: false}};
        case CREATE_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
            return {...state, createUser: {processing: false, success: true, errors: {}}};
        case CREATE_USER_FAILURE:
        case UPDATE_USER_FAILURE:
            return {...state, createUser: {processing: false, errors: action.payload}};
        case DELETE_USER:
            return {...state, deleteUser: {processing: true}};
        case DELETE_USER_SUCCESS:
            return {...state, deleteUser: {processing: false, success: true}};
        case DELETE_USER_FAILURE:
            return {...state, deleteUser: {processing: false, error: true}};
        case RESET_CREATE_USER:
            return {...state, createUser: INITIAL_STATE.createUser};
        case RESET_DELETE_USER:
            return {...state, deleteUser: INITIAL_STATE.deleteUser};
        default:
            return state;
    }
}

// Action Creators
export function getUsers(payload) {
    return {type: GET_USERS, payload};
}

export function createUser(payload) {
    return {type: CREATE_USER, payload};
}

export function updateUser(payload) {
    return {type: UPDATE_USER, payload};
}

export function deleteUser(payload) {
    return {type: DELETE_USER, payload};
}

export function resetCreateUser() {
    return {type: RESET_CREATE_USER};
}

export function resetDeleteUser() {
    return {type: RESET_DELETE_USER};
}
