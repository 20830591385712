import {call, put, takeLatest} from 'redux-saga/effects';
import * as Actions from 'redux/users';
import * as Config from 'config/constants';
import Http from 'util/http';

/** Get Users Saga */
function getUsersAPI(data) {
    return Http('GET', Config.API_URL + '/api/users', data, []);
}

function* getUsers(action) {
    try {
        const response = yield call(getUsersAPI, action.payload);
        yield put({type: Actions.GET_USERS_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.GET_USERS_FAILURE});
    }
}

/** Create User Saga */
function createUserAPI(data) {
    return Http('POST', Config.API_URL + '/api/users', null, data);
}

function* createUser(action) {
    try {
        const response = yield call(createUserAPI, action.payload);
        yield put({type: Actions.CREATE_USER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.CREATE_USER_FAILURE, payload: e.response.data.errors ?? {}});
    }
}

/** Update User Saga */
function updateUserAPI(data) {
    return Http('PUT', Config.API_URL + `/api/users/${data.id}`, null, data);
}

function* updateUser(action) {
    try {
        const response = yield call(updateUserAPI, action.payload);
        yield put({type: Actions.UPDATE_USER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.UPDATE_USER_FAILURE, payload: e.response.data.errors ?? {}});
    }
}

/** Delete User Saga */
function deleteUserAPI(data) {
    return Http('DELETE', Config.API_URL + `/api/users/${data.id}`, null, []);
}

function* deleteUser(action) {
    try {
        const response = yield call(deleteUserAPI, action.payload);
        yield put({type: Actions.DELETE_USER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.DELETE_USER_FAILURE});
    }
}

export default [
    takeLatest(Actions.GET_USERS, getUsers),
    takeLatest(Actions.CREATE_USER, createUser),
    takeLatest(Actions.UPDATE_USER, updateUser),
    takeLatest(Actions.DELETE_USER, deleteUser),
];
