import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar, faFileExport} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {getDashboard, exportDashboard} from "redux/data";
import Organization from "./organization";

const Dashboard = () => {
    const state = useSelector(state => state.data.dashboard);

    const dispatch = useDispatch();
    const actions = bindActionCreators({getDashboard, exportDashboard}, dispatch)

    // local state
    const [report, setReport] = useState('org');
    const [spec, setSpec] = useState('current');

    // effects
    useEffect(() => {
        actions.getDashboard({report, spec});
    }, [report, spec]);

    const year = new Date().getFullYear();
    const data = spec === 'current' ? {[year]: state.data[year] ?? []} : state.data; // we don't need excess data

    return (<div className="px-4 py-10 space-y-6">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faChartBar}/> Dashboard (Summary Reports)
            </h1>
            <div className="flex flex-row space-x-2">
                <select className="border px-2 py-1" value={spec} onChange={e => setSpec(e.target.value)}>
                    <option value="current">Current Year</option>
                    <option value="yoy">Year Over Year</option>
                </select>
            </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={report === 'org'} value="org" onChange={() => setReport('org')}/>
                <span>Organization Type</span>
            </div>
        </div>

        {!state.loading && <div className="flex flex-row justify-center">
            {!state.exporting && <button title="Export To Excel"
                                         onClick={() => actions.exportDashboard({report, spec})}
                                         className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                <FontAwesomeIcon icon={faFileExport}/> Export
            </button>}
            {state.exporting && <span className="text-xs">Exporting...</span>}
        </div>}
        {report === 'org' && <Organization loading={state.loading} data={data} />}

    </div>);
}

export default Dashboard;
