import React, {useEffect, useState} from 'react';
import Table from "elements/table";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {getDetailReport, exportDetailReport} from "redux/data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faTable} from "@fortawesome/free-solid-svg-icons";


const DetailReport = () => {
    const state = useSelector(({data: {detail}}) => detail);
    const dispatch = useDispatch();
    const actions = bindActionCreators({getDetailReport, exportDetailReport}, dispatch);

    const [page, setPage] = useState(1);
    const [goToPage, setGoToPage] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState(null);
    const [multiSort, setMultiSort] = useState([]);
    const [filters, setFilters] = useState({});
    const [sendFilters, setSendFilters] = useState({});
    const [timerID, setTimerID] = useState(null);

    useEffect(() => {
        actions.getDetailReport({});
    }, []);

    useEffect(() => {
        actions.getDetailReport({
            page,
            perPage,
            ...filters,
            ...(sort ?? {}),
            ...(multiSort.length > 0 ? {multiSort} : {}),
        });
    }, [page, perPage, sendFilters, sort, multiSort]);

    const changeFilters = (field, value) => {
        if (timerID) clearTimeout(timerID);

        setFilters((f) => {

            const {[field]: omit, ...ft} = f;

            return {
                ...ft, ...(value === '' ? {} : {[field]: value}),
            }
        });

        setPage(1);

        setTimerID(setTimeout(() => {
            setSendFilters(filters);
        }, 500));

    };

    // table columns
    const columns = [
        {name: 'ID', field: 'id', filter: {type: 'string'}},
        {name: 'Username', field: 'username', filter: {type: 'string'}},
        {name: 'First Name', field: 'first_name', filter: {type: 'string'}},
        {name: 'Last Name', field: 'last_name', filter: {type: 'string'}},
        {name: 'Email', field: 'email', filter: {type: 'string'}},
        {name: 'Electric Reg Date', field: 'elec_reg_date', filter: {type: 'string'}},
        {name: 'Electric Cert Date', field: 'elec_cert_date', filter: {type: 'string'}},
        {name: 'Gas Reg Date', field: 'gas_reg_date', filter: {type: 'string'}},
        {name: 'Gas Cert Date', field: 'gas_cert_date', filter: {type: 'string'}},
        {name: 'Phone', field: 'phone', filter: {type: 'string'}},
        {name: 'Job Title', field: 'job_title', filter: {type: 'string'}},
        {name: 'Job Type', field: 'job_type', filter: {type: 'string'}},
        {name: 'Other Job Type', field: 'other_job_type', filter: {type: 'string'}},
        {name: 'Work Type', field: 'work_type', filter: {type: 'string'}},
        {name: 'Other Work Type', field: 'other_work_type', filter: {type: 'string'}},
        {name: 'Organization Name', field: 'organization', filter: {type: 'string'}},
        {name: 'Organization Type', field: 'organization_type', filter: {type: 'string'}},
        {name: 'Organization State', field: 'organization_state', filter: {type: 'string'}},
        {name: 'County', field: 'county', filter: {type: 'string'}},
        {name: 'Zip Code', field: 'zip', filter: {type: 'string'}},
        {name: 'Jurisdiction', field: 'jurisdiction', filter: {type: 'string'}},
        {name: 'How did you hear about us?', field: 'hear_about', filter: {type: 'string'}},
        {name: 'How did you hear about us? (other)', field: 'other_hear_about', filter: {type: 'string'}},
    ];


    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900"><FontAwesomeIcon icon={faTable}/> Registration / Completion Detail
                Report</h1>
        </div>
        <Table page={page}
               perPage={perPage}
               goToPage={goToPage}
               setPage={setPage}
               setPerPage={setPerPage}
               setGoToPage={setGoToPage}
               bulkActions={<div>
                   {!state.exporting && <button title="Export To Excel"
                           onClick={() => actions.exportDetailReport({
                               ...filters,
                               ...(sort ?? {}),
                               ...(multiSort.length > 0 ? {multiSort} : {}),
                           })}
                         className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                       <FontAwesomeIcon icon={faFileExport}/> Export
                   </button>}
                   {state.exporting && <span className="text-xs">Exporting...</span>}
               </div>}
               clearFilters={() => {
                   setFilters({});
                   setSendFilters({});
               }}
               data={state.data}
               loading={state.loading}
               filters={filters}
               sort={sort}
               multiSort={multiSort}
               setFilters={changeFilters}
               setSort={setSort}
               setMultiSort={setMultiSort}
               name="All Users"
               columns={columns}/>
    </div>);
};

export default DetailReport;
