import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import {faFrownOpen, faHome} from '@fortawesome/free-solid-svg-icons';

const Notfound = () => {
    return (
        <div className="h-screen w-screen bg-gray-800 flex flex-col justify-center items-center space-y-6">
            <div className="text-white text-3xl space-x-4">
                <FontAwesomeIcon icon={faFrownOpen}/>
                <span>The page you're looking for wasn't found.</span>
            </div>
            <Link to="/">
                <span className="px-4 py-2 rounded bg-blue-600 text-white text-lg space-x-2">
                    <FontAwesomeIcon icon={faHome}/><span>Home</span>
                </span>
            </Link>
        </div>
    );
}

export default Notfound;
