import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faLock, faSave, faSpinner, faUserAlt, faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {resetCreateUser, updateUser} from "redux/users";
import {fetchUser} from "redux/registration";
import cn from "classnames";

const Account = () => {
    const user = useSelector(state => state.registration.user);
    const state = useSelector(state => state.users.createUser);

    const dispatch = useDispatch();
    const actions = bindActionCreators({resetCreateUser, updateUser, fetchUser}, dispatch)

    // local state
    const [name, setName] = useState(user ? user.name : '');
    const [username, setUsername] = useState(user ? user.username : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [password, setPassword] = useState('');
    const [passConfirm, setPassConfirm] = useState('');
    const [hideErrors, setHideErrors] = useState({});
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [process, setProcess] = useState(1);

    // effects
    useEffect(() => actions.resetCreateUser, []);
    useEffect(() => setHideErrors(cur => ({...cur, name: true})), [name]);
    useEffect(() => setHideErrors(cur => ({...cur, username: true})), [username]);
    useEffect(() => setHideErrors(cur => ({...cur, email: true})), [email]);
    useEffect(() => {
        !state.processing && setHideErrors({});
    }, [state.processing]);
    useEffect(() => {
        if (state.success) {
            setDisplaySuccess(true);
            actions.fetchUser();
        }

    }, [state.success]);

    // Handlers
    const showError = (field) => state.errors[field] && !hideErrors[field];

    const resetPasswordDisabled = password === '' || passConfirm === '' || password.length < 6 || password !== passConfirm;
    const processingInfo = state.processing && process === 1;
    const processingPass = state.processing && process === 2;
    const successInfo = displaySuccess && state.success && process === 1;
    const successPass = displaySuccess && state.success && process === 2;

    const payloadInfo = {
        id: user.id, name, username, email,
    };

    const payloadPass = {id: user.id, password}

    return (<div className="px-4 py-10 space-y-6">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faUserCircle}/> My Account
            </h1>
        </div>
        {/* update personal data */}
        <div className="flex flex-col space-y-4 w-3/5 bg-gray-100 px-6 py-6 border rounded-lg">
            <h3 className="text-blue-900 text-2xl"><FontAwesomeIcon icon={faUserAlt}/> Update your information</h3>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Name</span>
                <div className="flex flex-col">
                    <input value={name} onChange={e => setName(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('name') && 'border-red-500'}`}
                           placeholder="Enter Full Name" autoComplete="off" autoFocus/>
                    {showError('name') && <span className="text-sm text-red-600">{state.errors.name[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Username</span>
                <div className="flex flex-col">
                    <input value={username} onChange={e => setUsername(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('username') && 'border-red-500'}`}
                           placeholder="Enter Username" autoComplete="off"/>
                    {showError('username') && <span className="text-sm text-red-600">{state.errors.username[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Email Address</span>
                <div className="flex flex-col">
                    <input value={email} onChange={e => setEmail(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('email') && 'border-red-500'}`}
                           placeholder="Enter email Address" autoComplete="off"/>
                    {showError('email') && <span className="text-sm text-red-600">{state.errors.email[0]}</span>}
                </div>
            </div>
            {successInfo && <span className="text-sm text-green-600 text-center">Info updated successfully</span>}
            <div className="flex flex-row justify-end">
                {!processingInfo && <button onClick={() => {
                    setProcess(1);
                    setDisplaySuccess(false);
                    actions.updateUser(payloadInfo);
                }}
                                            className="border rounded py-2 px-3 border-blue-600 bg-blue-700 text-white hover:bg-white hover:text-blue-700">
                    <FontAwesomeIcon icon={faSave}/> Update
                </button>}
                {processingInfo && <div className="text-gray-600 text-center">
                    <FontAwesomeIcon icon={faSpinner}/> Updating Info...
                </div>}
            </div>
        </div>

        {/* update password section */}
        <div className="flex flex-col space-y-4 w-3/5 bg-gray-100 px-6 py-6 border rounded-lg">
            <h3 className="text-blue-900 text-2xl"><FontAwesomeIcon icon={faLock}/> Update your password</h3>
            <span className="text-xs text-blue-900 font-bold">Ensure the new password is 6 characters or more</span>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Password</span>
                <div className="flex flex-col">
                    <input value={password} onChange={e => setPassword(e.target.value)} type="password"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('password') && 'border-red-500'}`}
                           placeholder="Enter your new password" autoComplete="off"/>
                    {showError('password') &&
                        <span className="text-sm text-red-600">{state.createUser.errors.password[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Confirm Password</span>
                <div className="flex flex-col">
                    <input value={passConfirm} onChange={e => setPassConfirm(e.target.value)} type="password"
                           className="border rounded w-72 h-12 px-4 text-sm outline-none"
                           placeholder="Confirm your new password" autoComplete="off"/>
                </div>
            </div>
            {successPass && <span className="text-sm text-green-600 text-center">Password updated successfully</span>}
            <div className="flex flex-row justify-end">
                {!processingPass && <button disabled={resetPasswordDisabled} onClick={() => {
                    setProcess(2);
                    setDisplaySuccess(false);
                    actions.updateUser(payloadPass);
                }}
                                            className={cn("border rounded py-2 px-3", resetPasswordDisabled ? "border-gray-100 bg-gray-200 text-gray-500" : "border-blue-600 bg-blue-700 text-white hover:bg-white hover:text-blue-700")}>
                    <FontAwesomeIcon icon={faSave}/> Update
                </button>}
                {processingPass && <div className="text-gray-600 text-center">
                    <FontAwesomeIcon icon={faSpinner}/> Updating Password...
                </div>}
            </div>
        </div>
    </div>);
}

export default Account;
