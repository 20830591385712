import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faDownload} from "@fortawesome/free-solid-svg-icons";

const OrganizationType = ({height = 600}) => {

    const [chartOne, setChartOne] = useState(4);
    const [chartTwo, setChartTwo] = useState(14);

    function chartLoad(one, two)  {
        setChartOne(one);
        setChartTwo(two);
    }

    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faBuilding}/> Registration / Completion By Organization Type
            </h1>
        </div>
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 4} value={4} onChange={() => chartLoad(4, 14)}/>
                <span>Org Type Only</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 9} value={9} onChange={() => chartLoad(9, 19)}/>
                <span>Jurisdiction - NY</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 10} value={10} onChange={() => chartLoad(10, 20)}/>
                <span>Jurisdiction - MA / RI / Other</span>
            </div>
        </div>
        <div className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_ORG&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/>PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_ORG&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartOne}`} alt="chart"/>
            </div>
        </div>
        <br/><br/>
        <div className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_ORG&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_ORG&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartTwo}`} alt="chart"/>
            </div>
        </div>
    </div>);
};

export default OrganizationType;
