import React from 'react';
import cn from 'classnames';
import style from './login.module.css';

const Layout = ({children}) => {
    return (<div className="w-screen h-screen flex flex-row">
            <div className="h-full w-3/5 flex flex-col justify-center items-center">
                {children}
            </div>
            <div className={cn("h-full w-2/5 border-l flex flex-col items-center justify-center", style.box)}>
                <img className="w-4/6" src="/logo.png"/>
                <span className="text-2xl text-blue-400">Reporting</span>
            </div>
        </div>);
}

export default Layout;
