import React, {useEffect, useState} from 'react';
import Table from "elements/table";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {getUsers} from "redux/users";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusSquare, faTrash, faUsers} from "@fortawesome/free-solid-svg-icons";
import CreateUserDialog from "./CreateUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";


const Users = () => {
    const state = useSelector(({users}) => users);
    const dispatch = useDispatch();
    const actions = bindActionCreators({getUsers}, dispatch);

    const [page, setPage] = useState(1);
    const [goToPage, setGoToPage] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [user, setUser] = useState(null);

    const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);


    useEffect(() => {
        actions.getUsers({});
    }, []);

    useEffect(() => {
        actions.getUsers({page, perPage});
    }, [page, perPage]);

    const refetch = () => actions.getUsers({page, perPage});


    // table columns
    const columns = [
        {name: 'ID', field: 'id'},
        {name: 'Name', field: 'name'},
        {name: 'Username', field: 'username'},
        {name: 'Email', field: 'email'},
        {name: 'Is Admin?', render: r => r.admin ? 'Yes' : 'No'},
        {
            name: '', render: r => (<div className="flex flex-row space-x-3 text-sm">
            <span onClick={() => {
                setUser(r);
                setShowCreateUserDialog(true);
            }} title="Edit User" className="cursor-pointer text-blue-600"><FontAwesomeIcon icon={faEdit}/></span>
                <span onClick={() => {
                    setUser(r);
                    setShowDeleteUserDialog(true);
                }} title="Delete User" className="cursor-pointer text-red-700"><FontAwesomeIcon icon={faTrash}/></span>
            </div>)
        },
    ];


    return (<div className="px-4 py-10">
        {showCreateUserDialog && <CreateUserDialog refetch={refetch} user={user} onClose={() => setShowCreateUserDialog(false)}/>}
        {showDeleteUserDialog && <DeleteUserDialog refetch={refetch} user={user} onClose={() => setShowDeleteUserDialog(false)}/>}
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900"><FontAwesomeIcon icon={faUsers}/> Manage App Users</h1>
            <button onClick={() => {
                setUser(null);
                setShowCreateUserDialog(true);
            }}
                    className="text-blue-700 border border-blue-700 px-2 py-1 hover:text-gray-100 hover:bg-blue-700">
                <FontAwesomeIcon icon={faPlusSquare}/> New User
            </button>
        </div>
        <Table page={page}
               perPage={perPage}
               goToPage={goToPage}
               setPage={setPage}
               setPerPage={setPerPage}
               setGoToPage={setGoToPage}
               clearFilters={() => {}}
               data={state.data}
               loading={state.loading}
               filters={{}}
               setFilters={() => {}}
               name="Users"
               noSearch={true}
               noHiddenColumns={true}
               noSort={true}
               columns={columns}/>
    </div>);
};

export default Users;
