import axios from 'axios';
import {CANCEL} from "redux-saga";

/**
 * Add cancellation to saga yield requests
 *
 * @param method
 * @param url
 * @param params
 * @param data
 * @param responseType
 */
const request = (method, url, params, data, responseType = undefined) => {
    const source = axios.CancelToken.source();
    const request = axios.request({
        method,
        url,
        params,
        data,
        cancelToken: source.token,
        withCredentials: true,
        responseType
    });

    request[CANCEL] = () => source.cancel();

    return request;
};

export default request;
