import React, {useEffect} from 'react';
import {Navigate, NavLink, Route, Routes, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChartBar,
    faUserCircle,
    faSignOutAlt,
    faUsers,
    faTable,
    faNoteSticky,
    faChartLine,
    faMap,
    faBuilding,
    faPeopleGroup
} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {logout, fetchUser} from "redux/registration";
import Dashboard from 'components/pages/dashboard';
import Users from 'components/pages/users';
import DetailReport from 'components/pages/detail-report';
import SummaryReport from "components/pages/summary";
import ByCommodity from "components/pages/by-commodity";
import Jurisdiction from "components/pages/jurisdiction";
import OrganizationType from "components/pages/organization-type";
import Account from "components/pages/account";
import Population from "components/pages/population";

const Home = () => {
    const state = useSelector(state => state.registration);
    const location = useLocation();

    const dispatch = useDispatch();
    const actions = bindActionCreators({logout, fetchUser}, dispatch);

    useEffect(() => {
        actions.fetchUser();
    }, []);

    if (!state.user) return <Navigate to="/login" state={{from: location}}/>;

    const menu = [{
        name: 'Summary Report', icon: faNoteSticky, path: '/summary-report',
    }, {
        name: 'Year Over Year', icon: faChartLine, path: '/yoy',
    }, {
        name: 'Jurisdiction', icon: faMap, path: '/jurisdiction',
    }, {
        name: 'Organization Type', icon: faBuilding, path: '/org',
    }, {
        name: 'Population', icon: faPeopleGroup, path: '/population',
    }, {
        name: 'Detail Report', icon: faTable, path: '/detail-report',
    },  {
        separator: true,
    }, {
        name: 'Users', icon: faUsers, path: '/users', admin: true,
    }, {
        name: state.user.name ? state.user.name.split(' ')[0] : 'My Account', icon: faUserCircle, path: '/account',
    }, {
        name: 'Logout', icon: faSignOutAlt, path: false, onClick: actions.logout
    },];

    return (<div className="flex flex-row h-screen w-screen">
        <div className="flex h-full w-1/5 flex-col items-center pb-5 bg-blue-900">
            <div className="text-2xl h-30 pt-2 flex flex-col items-center bg-white w-full">
                <img width="250" src="/logo.png"/>
                <span className="text-blue-400">Reporting</span>
            </div>
            <div className="mt-10">
                <ul className={`space-y-4 text-white`}>
                    {menu.filter(i => !i.admin || state.user.admin).map((r, i) => r.separator ?
                        <li key={`menu-${i}`} className="border-b border-blue-600 pt-10"/> : (r.path ?
                            <li key={`menu-${i}`} className="hover:text-blue-400 cursor-pointer">
                                <NavLink to={r.path}
                                         className={({isActive}) => isActive ? "text-blue-400" : ""}>
                                            <span className="space-x-2">
                                                <FontAwesomeIcon icon={r.icon}/><span>{r.name}</span>
                                            </span>
                                </NavLink>
                            </li> : <li key={`menu-${i}`} onClick={r.onClick}
                                        className="space-x-2 hover:text-blue-400 cursor-pointer">
                                <FontAwesomeIcon icon={r.icon}/><span>{r.name}</span>
                            </li>))}
                </ul>
            </div>
        </div>
        <div className="flex h-full w-4/5 flex-col overflow-scroll">
            <Routes>
                <Route index element={<SummaryReport/>}/>
                <Route path="yoy" element={<ByCommodity/>}/>
                <Route path="jurisdiction" element={<Jurisdiction/>}/>
                <Route path="org" element={<OrganizationType/>}/>
                <Route path="population" element={<Population/>}/>
                <Route path="detail-report" element={<DetailReport/>}/>
                <Route path="summary-report" element={<SummaryReport/>}/>
                <Route path="dashboard" element={<Dashboard/>}/>
                <Route path="users" element={<Users/>}/>
                <Route path="account" element={<Account/>}/>
                <Route path="*" element={<Navigate to="/404" state={{from: location}}/>}/>
            </Routes>
        </div>
    </div>);
}

export default Home;
