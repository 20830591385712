import React, {useEffect, useState} from 'react';
import Dialog from "elements/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faUser, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {createUser, updateUser, resetCreateUser} from "redux/users";

const CreateUserDialog = ({onClose, user = null, refetch}) => {
    const update = user !== null;

    // store & actions
    const state = useSelector(({users}) => users);
    const dispatch = useDispatch();
    const actions = bindActionCreators({createUser, updateUser, resetCreateUser}, dispatch);

    // local state
    const [name, setName] = useState(user ? user.name : '');
    const [username, setUsername] = useState(user ? user.username : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [password, setPassword] = useState('');
    const [admin, setAdmin] = useState(false);
    const [hideErrors, setHideErrors] = useState({});

    // effects
    useEffect(() => actions.resetCreateUser, []);
    useEffect(() => setHideErrors(cur => ({...cur, name: true})), [name]);
    useEffect(() => setHideErrors(cur => ({...cur, username: true})), [username]);
    useEffect(() => setHideErrors(cur => ({...cur, email: true})), [email]);
    useEffect(() => setHideErrors(cur => ({...cur, password: true})), [password]);
    useEffect(() => {
        !state.createUser.processing && setHideErrors({});
    }, [state.createUser.processing]);
    useEffect(() => {
        state.createUser.success && refetch();
    }, [state.createUser.success]);

    const showError = (field) => state.createUser.errors[field] && !hideErrors[field];
    const payload = {
        name,
        username,
        email,
        admin, ...(update && password === '' ? {} : {password}), ...(update ? {id: user.id} : {}),
    }

    return (<Dialog onClose={onClose} width={500}>
        <div className="flex flex-col space-y-4">
            {!update && <h1 className="text-blue-800 text-lg"><FontAwesomeIcon icon={faUserPlus}/> Create User</h1>}
            {update && <h1 className="text-blue-800 text-lg"><FontAwesomeIcon icon={faUser}/> Update User</h1>}
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Name</span>
                <div className="flex flex-col">
                    <input value={name} onChange={e => setName(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('name') && 'border-red-500'}`}
                           placeholder="Enter Full Name" autoComplete="off" autoFocus/>
                    {showError('name') &&
                        <span className="text-sm text-red-600">{state.createUser.errors.name[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Username</span>
                <div className="flex flex-col">
                    <input value={username} onChange={e => setUsername(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('username') && 'border-red-500'}`}
                           placeholder="Enter Username" autoComplete="off"/>
                    {showError('username') &&
                        <span className="text-sm text-red-600">{state.createUser.errors.username[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Email Address</span>
                <div className="flex flex-col">
                    <input value={email} onChange={e => setEmail(e.target.value)} type="text"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('email') && 'border-red-500'}`}
                           placeholder="Enter email Address" autoComplete="off"/>
                    {showError('email') &&
                        <span className="text-sm text-red-600">{state.createUser.errors.email[0]}</span>}
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <span className="w-28">Password</span>
                <div className="flex flex-col">
                    <input value={password} onChange={e => setPassword(e.target.value)} type="password"
                           className={`border rounded w-72 h-12 px-4 text-sm outline-none ${showError('password') && 'border-red-500'}`}
                           placeholder="Enter Password" autoComplete="off"/>
                    {showError('password') &&
                        <span className="text-sm text-red-600">{state.createUser.errors.password[0]}</span>}
                </div>
            </div>
            {!update && <div className="flex flex-row items-center space-x-4">
                <span className="w-28 mr-10">Is Admin?</span>
                <input value={admin}
                       type="checkbox"
                       onChange={e => setAdmin(!admin)}
                       className="border rounded w-5 h-5 px-4 text-sm"/>
            </div>}
            {state.createUser.success && <span className="text-sm text-green-600 text-center">
                        {update ? 'User updated successfully' : 'User created successfully'}
                    </span>}
            <div className="flex flex-row justify-end">
                {state.createUser.processing && <span>Saving...</span>}
                {!state.createUser.processing && (!state.createUser.success || update) &&
                    <button onClick={() => update ? actions.updateUser(payload) : actions.createUser(payload)}
                            className="text-blue-700 border border-blue-700 px-2 py-1 hover:text-gray-100 hover:bg-blue-700">
                        <FontAwesomeIcon icon={faSave}/> {update ? 'Update' : 'Create'}
                    </button>}
            </div>
        </div>

    </Dialog>);
}

export default CreateUserDialog;
