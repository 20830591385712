import {call, put, takeLatest} from 'redux-saga/effects';
import * as Actions from 'redux/registration';
import * as Config from 'config/constants';
import Http from 'util/http';

/** Login Saga */
function loginAPI(data) {
    return Http('POST', Config.API_URL + '/api/login', null, data);
}

function* loginUser(action) {
    try {
        const response = yield call(loginAPI, action.payload);
        yield put({type: Actions.LOGIN_SUCCESS});
    } catch (e) {
        yield put({type: Actions.LOGIN_FAILURE, payload: e.response.data});
    }
}

/** Forgot Password Saga */
function forgotPasswordAPI(data) {
    return Http('POST', Config.API_URL + '/api/forgot-password', null, data);
}

function* forgotPassword(action) {
    try {
        const response = yield call(forgotPasswordAPI, action.payload);
        yield put({type: Actions.FORGOT_PASSWORD_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.FORGOT_PASSWORD_FAILURE});
    }
}

/** Reset Password Saga */
function resetPasswordAPI(data) {
    return Http('POST', Config.API_URL + '/api/reset-password', null, data);
}

function* resetPassword(action) {
    try {
        const response = yield call(resetPasswordAPI, action.payload);
        yield put({type: Actions.RESET_PASSWORD_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.RESET_PASSWORD_FAILURE});
    }
}

/** Logout User Saga */
function logoutUserAPI() {
    return Http('POST', Config.API_URL + '/api/logout', null, []);
}

/** Logout Saga*/
function* logoutUser() {
    try {
        yield call(logoutUserAPI);
    } catch (e) {
    }
    yield put({type: Actions.LOGOUT_SUCCESS});
}

/** Fetch User Saga */
function fetchUserAPI() {
    return Http('GET', Config.API_URL + '/api/me', null, []);
}

function* fetchUser(action) {
    try {
        const response = yield call(fetchUserAPI);
        yield put({type: Actions.FETCH_USER_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.FETCH_USER_FAILURE});
    }
}

export default [
    takeLatest(Actions.LOGIN, loginUser),
    takeLatest(Actions.FETCH_USER, fetchUser),
    takeLatest(Actions.LOGOUT, logoutUser),
    takeLatest(Actions.FORGOT_PASSWORD, forgotPassword),
    takeLatest(Actions.RESET_PASSWORD, resetPassword),
];
