import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const ByCommodity = ({height = 700}) => {

    const [chartOne, setChartOne] = useState(1);
    const [chartTwo, setChartTwo] = useState(11);

    function chartLoad(one, two)  {
        setChartOne(one);
        setChartTwo(two);
    }

    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faChartLine}/> Year Over Year Registrations / Completions
            </h1>
        </div>
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 1} value={1} onChange={() => chartLoad(1, 11)}/>
                <span>All</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 5} value={5} onChange={() => chartLoad(5, 15)}/>
                <span>Gas Only</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 6} value={6} onChange={() => chartLoad(6, 16)}/>
                <span>Electric Only</span>
            </div>
        </div>
        <div className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_YOY&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/>PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_YOY&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartOne}`} alt="chart"/>
            </div>
        </div>
        <br/><br/>
        <div className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_YOY&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_YOY&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartTwo}`} alt="chart"/>
            </div>
        </div>
    </div>);
};

export default ByCommodity;
