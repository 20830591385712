// Data Store
export const GET_DETAIL_REPORT = 'data/get_detail_report';
export const GET_DETAIL_REPORT_SUCCESS = 'data/get_detail_report_success';
export const GET_DETAIL_REPORT_FAILURE = 'data/get_detail_report_failure';
export const EXPORT_DETAIL_REPORT = 'data/export_detail_report';
export const EXPORT_DETAIL_REPORT_SUCCESS = 'data/export_detail_report_success';
export const EXPORT_DETAIL_REPORT_FAILURE = 'data/export_detail_report_failure';
export const GET_SUMMARY_REPORT = 'data/get_summary_report';
export const GET_SUMMARY_REPORT_SUCCESS = 'data/get_summary_report_success';
export const GET_SUMMARY_REPORT_FAILURE = 'data/get_summary_report_failure';
export const EXPORT_SUMMARY_REPORT = 'data/export_summary_report';
export const EXPORT_SUMMARY_REPORT_SUCCESS = 'data/export_summary_report_success';
export const EXPORT_SUMMARY_REPORT_FAILURE = 'data/export_summary_report_failure';
export const GET_CHART = 'data/get_chart';
export const GET_CHART_SUCCESS = 'data/get_chart_success';
export const GET_CHART_FAILURE = 'data/get_chart_failure';
export const EXPORT_CHART = 'data/export_chart';
export const EXPORT_CHART_SUCCESS = 'data/export_chart_success';
export const EXPORT_CHART_FAILURE = 'data/export_chart_failure';
export const RESET_CHART = 'data/reset_chart';
export const GET_DASHBOARD_REPORT = 'data/get_dashboard_report';
export const GET_DASHBOARD_REPORT_SUCCESS = 'data/get_dashboard_report_success';
export const GET_DASHBOARD_REPORT_FAILURE = 'data/get_dashboard_report_failure';
export const EXPORT_DASHBOARD_REPORT = 'data/export_dashboard_report';
export const EXPORT_DASHBOARD_REPORT_SUCCESS = 'data/export_dashboard_report_success';
export const EXPORT_DASHBOARD_REPORT_FAILURE = 'data/export_dashboard_report_failure';


// Initial State
const INITIAL_STATE = {
    detail: {
        data: {}, loading: false, exporting: false,
    }, chart: {
        loading: false, exporting: false, data: {},
    }, summary: {
        data: [], loading: false,
    }, dashboard: {
        loading: false, exporting: false, data: {},
    }
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_DETAIL_REPORT:
            return {...state, detail: {...state.detail, loading: true}};
        case GET_DETAIL_REPORT_SUCCESS:
            return {...state, detail: {...state.detail, data: action.payload, loading: false}};
        case GET_DETAIL_REPORT_FAILURE:
            return {...state, detail: {...state.detail, data: {}, loading: false}};
        case GET_SUMMARY_REPORT:
            return {...state, summary: {...state.summary, loading: true}};
        case GET_SUMMARY_REPORT_SUCCESS:
            return {...state, summary: {...state.summary, data: action.payload, loading: false}};
        case GET_SUMMARY_REPORT_FAILURE:
            return {...state, summary: {...state.summary, data: {}, loading: false}};
        case GET_DASHBOARD_REPORT:
            return {...state, dashboard: {...state.dashboard, loading: true}};
        case GET_DASHBOARD_REPORT_SUCCESS:
            return {...state, dashboard: {...state.dashboard, data: action.payload, loading: false}};
        case GET_DASHBOARD_REPORT_FAILURE:
            return {...state, dashboard: {...state.dashboard, data: {}, loading: false}};
        case EXPORT_DETAIL_REPORT:
            return {...state, detail: {...state.detail, exporting: true}};
        case EXPORT_DETAIL_REPORT_SUCCESS:
            const blob = new Blob([action.payload], {type: 'application/xlsx'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Detail-Report.xlsx';
            link.click();
            return {...state, detail: {...state.detail, exporting: false}};
        case EXPORT_DETAIL_REPORT_FAILURE:
            return {...state, detail: {...state.detail, exporting: false}};
        case EXPORT_SUMMARY_REPORT:
            return {...state, summary: {...state.summary, exporting: true}};
        case EXPORT_SUMMARY_REPORT_SUCCESS:
            const sblob = new Blob([action.payload], {type: 'application/xlsx'});
            const slink = document.createElement('a');
            slink.href = window.URL.createObjectURL(sblob);
            slink.download = 'Summary-Report.xlsx';
            slink.click();
            return {...state, summary: {...state.summary, exporting: false}};
        case EXPORT_SUMMARY_REPORT_FAILURE:
            return {...state, summary: {...state.summary, exporting: false}};
        case EXPORT_DASHBOARD_REPORT:
            return {...state, dashboard: {...state.dashboard, exporting: true}};
        case EXPORT_DASHBOARD_REPORT_SUCCESS:
            const dblob = new Blob([action.payload], {type: 'application/xlsx'});
            const dlink = document.createElement('a');
            dlink.href = window.URL.createObjectURL(dblob);
            dlink.download = 'Summary-Report.xlsx';
            dlink.click();
            return {...state, dashboard: {...state.dashboard, exporting: false}};
        case EXPORT_DASHBOARD_REPORT_FAILURE:
            return {...state, dashboard: {...state.dashboard, exporting: false}};
        case GET_CHART:
            return {...state, chart: {...state.chart, loading: true}};
        case GET_CHART_SUCCESS:
            let imageBlob = new Blob([action.payload.data], {type: 'image/png'})
            return {
                ...state, chart: {
                    ...state.chart,
                    data: {...state.chart.data, [action.payload.id]: URL.createObjectURL(imageBlob)},
                    loading: false
                }
            };
        case GET_CHART_FAILURE:
            return {...state, chart: {...state.chart, loading: false}};
        case EXPORT_CHART:
            return {...state, chart: {...state.chart, exporting: true}};
        case EXPORT_CHART_SUCCESS:
            const type = action.payload.type === 'png' ? 'image/png' : 'application/pdf';
            const chartBlob = new Blob([action.payload.data], {type});
            const chartLink = document.createElement('a');
            chartLink.href = window.URL.createObjectURL(chartBlob);
            chartLink.download = `${action.payload.filename}.${action.payload.type}`;
            chartLink.click();
            return {...state, chart: {...state.chart, exporting: false}};
        case EXPORT_CHART_FAILURE:
            return {...state, chart: {...state.chart, exporting: false}};
        case RESET_CHART:
            return {...state, chart: INITIAL_STATE.chart};
        default:
            return state;
    }
}

// Action Creators
export function getDetailReport(payload) {
    return {type: GET_DETAIL_REPORT, payload};
}

export function getSummaryReport(payload) {
    return {type: GET_SUMMARY_REPORT, payload};
}

export function exportDetailReport(payload) {
    return {type: EXPORT_DETAIL_REPORT, payload};
}

export function exportSummaryReport(payload) {
    return {type: EXPORT_SUMMARY_REPORT, payload};
}

export function getChart(payload) {
    return {type: GET_CHART, payload};
}

export function exportChart(payload) {
    return {type: EXPORT_CHART, payload};
}

export function getDashboard(payload) {
    return {type: GET_DASHBOARD_REPORT, payload};
}

export function exportDashboard(payload) {
    return {type: EXPORT_DASHBOARD_REPORT, payload};
}
