import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";


const Dialog = ({onClose, children, width = 400, height}) => {
    return (<div className="absolute h-screen w-screen top-0 left-0 z-20 bg-opacity-20 bg-blue-900" onClick={onClose}>
        <div className="flex flex-col items-center pt-20">
            <div onClick={e => e.stopPropagation()} className="relative bg-white shadow-gray-600 shadow-2xl text-gray-800 p-4"
                 style={{width: `${width}px`, height: `${height ? height + 'px' : 'auto'}`}}>
                <div title="Close Window"
                     onClick={onClose}
                     className="absolute top-0 right-0 mt-1 mr-4 text-yellow-700 cursor-pointer">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
                {children}
            </div>
        </div>
    </div>);
}

export default Dialog;
