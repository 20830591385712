import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faExclamationTriangle,
    faInfoCircle,
    faMailForward,
    faSpinner,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, Navigate, useLocation} from "react-router-dom";
import {forgotPassword} from "redux/registration";
import Layout from "./layout";
import cn from "classnames";


const ForgotPassword = () => {
    const state = useSelector(state => state.registration.forgotPassword);
    let location = useLocation();

    const dispatch = useDispatch();
    const actions = bindActionCreators({forgotPassword}, dispatch)

    const [email, setEmail] = useState('');

    const forgotPasswordDisabled = email === '';

    if (state.user)
        return <Navigate to="/" state={{from: location}}/>;

    return (
        <Layout>
            <div className="flex flex-col space-y-4">
                <div className="text-gray-600">
                    <FontAwesomeIcon icon={faInfoCircle}/> Forgot password
                </div>
                {state.response.status !== 'ok' && <input value={email} onChange={e => setEmail(e.target.value)} type="email"
                       onKeyDown={e => e.key === 'Enter' && !forgotPasswordDisabled && actions.forgotPassword({email})}
                       className="border rounded w-72 h-12 px-4 text-sm"
                       placeholder="Enter your email" autoComplete="off" autoFocus/>}
                <Link to="/login" className="text-center"><span className="text-sm cursor-pointer text-blue-600"><FontAwesomeIcon icon={faArrowLeft} /> Back to login</span></Link>
                {!state.processing && state.response.status !== 'ok' &&
                    <button disabled={forgotPasswordDisabled} onClick={() => actions.forgotPassword({email})}
                            className={cn("border rounded", forgotPasswordDisabled ?
                                "border-gray-100 py-3 bg-gray-200 text-gray-500" :
                                "border-blue-600 py-3 bg-blue-700 text-white hover:bg-white hover:text-blue-700")}>
                        <FontAwesomeIcon icon={faMailForward}/> Send Reset Password Link
                    </button>}
                {state.processing && <div className="text-gray-600 text-center">
                    <FontAwesomeIcon icon={faSpinner}/> Sending Link...
                </div>}
                {state.response.status === 'error' && <div className="text-red-600 text-sm">
                    <FontAwesomeIcon icon={faExclamationTriangle}/> {state.response.msg}
                </div>}
                {state.response.status === 'ok' && <div className="text-green-600 text-sm">
                    <FontAwesomeIcon icon={faCheckCircle}/> {state.response.msg}
                </div>}
            </div>
        </Layout>
    );
}

export default ForgotPassword;
