import './App.css';
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store from './redux'
import Router from "components/Router";


function App() {
  return (
      <Provider store={store.store}>
        <PersistGate loading={<div>Loading..</div>} persistor={store.persistor}>
          <Router />
        </PersistGate>
      </Provider>
  );
}

export default App;
