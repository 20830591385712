import {call, put, takeLatest} from 'redux-saga/effects';
import * as Actions from 'redux/data';
import * as Config from 'config/constants';
import Http from 'util/http';

/** Get Detail Report Saga */
function getDetailReportAPI(data) {
    return Http('GET', Config.API_URL + '/api/detail', data, []);
}

function* getDetailReport(action) {
    try {
        const response = yield call(getDetailReportAPI, action.payload);
        yield put({type: Actions.GET_DETAIL_REPORT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.GET_DETAIL_REPORT_FAILURE});
    }
}

/** Get Summary Report Saga */
function getSummaryReportAPI(data) {
    return Http('GET', Config.API_URL + '/api/summary', data, []);
}

function* getSummaryReport(action) {
    try {
        const response = yield call(getSummaryReportAPI, action.payload);
        yield put({type: Actions.GET_SUMMARY_REPORT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.GET_SUMMARY_REPORT_FAILURE});
    }
}

/** Export Detail Report Saga */
function exportDetailReportAPI(data) {
    return Http('GET', Config.API_URL + '/api/detail-export', data, [], 'arraybuffer');
}

function* exportDetailReport(action) {
    try {
        const response = yield call(exportDetailReportAPI, action.payload);
        yield put({type: Actions.EXPORT_DETAIL_REPORT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.EXPORT_DETAIL_REPORT_FAILURE});
    }
}

/** Export Summary Report Saga */
function exportSummaryReportAPI(data) {
    return Http('GET', Config.API_URL + '/api/summary-export', data, [], 'arraybuffer');
}

function* exportSummaryReport(action) {
    try {
        const response = yield call(exportSummaryReportAPI, action.payload);
        yield put({type: Actions.EXPORT_SUMMARY_REPORT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.EXPORT_SUMMARY_REPORT_FAILURE});
    }
}

/** Get Chart Saga */
function getChartAPI(data) {
    return Http('GET', Config.API_URL + '/api/chart', data, [], 'arraybuffer');
}

function* getChart(action) {
    try {
        const response = yield call(getChartAPI, action.payload);
        yield put({type: Actions.GET_CHART_SUCCESS, payload: {id: action.payload.id, data: response.data}});
    } catch (e) {
        yield put({type: Actions.GET_CHART_FAILURE});
    }
}

/** Export chart Saga */
function exportChartAPI(data) {
    return Http('GET', Config.API_URL + '/api/chart-export', data, [], 'arraybuffer');
}

function* exportChart(action) {
    try {
        const response = yield call(exportChartAPI, action.payload);
        yield put({type: Actions.EXPORT_CHART_SUCCESS, payload: {...action.payload, data: response.data}});
    } catch (e) {
        yield put({type: Actions.EXPORT_CHART_FAILURE});
    }
}

/** Get Dashboard Saga */
function getDashboardAPI(data) {
    return Http('GET', Config.API_URL + '/api/dashboard', data, []);
}

function* getDashboard(action) {
    try {
        const response = yield call(getDashboardAPI, action.payload);
        yield put({type: Actions.GET_DASHBOARD_REPORT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({type: Actions.GET_DASHBOARD_REPORT_FAILURE});
    }
}

/** Export dashboard Saga */
function exportDashboardAPI(data) {
    return Http('GET', Config.API_URL + '/api/dashboard-export', data, [], 'arraybuffer');
}

function* exportDashboard(action) {
    try {
        const response = yield call(exportDashboardAPI, action.payload);
        yield put({type: Actions.EXPORT_DASHBOARD_REPORT_SUCCESS, payload: {...action.payload, data: response.data}});
    } catch (e) {
        yield put({type: Actions.EXPORT_DASHBOARD_REPORT_FAILURE});
    }
}

export default [
    takeLatest(Actions.GET_DETAIL_REPORT, getDetailReport),
    takeLatest(Actions.GET_SUMMARY_REPORT, getSummaryReport),
    takeLatest(Actions.EXPORT_DETAIL_REPORT, exportDetailReport),
    takeLatest(Actions.EXPORT_SUMMARY_REPORT, exportSummaryReport),
    takeLatest(Actions.GET_CHART, getChart),
    takeLatest(Actions.EXPORT_CHART, exportChart),
    takeLatest(Actions.GET_DASHBOARD_REPORT, getDashboard),
    takeLatest(Actions.EXPORT_DASHBOARD_REPORT, exportDashboard),
];
