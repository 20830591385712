import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faInfoCircle,
    faSignInAlt,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, Navigate, useLocation} from "react-router-dom";
import {login} from "redux/registration";
import Layout from "./layout";
import cn from "classnames";


const Login = () => {
    const state = useSelector(state => state.registration);
    let location = useLocation();

    const dispatch = useDispatch();
    const actions = bindActionCreators({login}, dispatch)

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const loginDisabled = username === '' || password === '';

    if (state.user)
        return <Navigate to="/" state={{from: location}}/>;

    return (
        <Layout>
            <div className="flex flex-col space-y-4">
                <div className="text-gray-600">
                    <FontAwesomeIcon icon={faInfoCircle}/> Please enter your login credentials
                </div>
                <input value={username} onChange={e => setUsername(e.target.value)} type="text"
                       onKeyDown={e => e.key === 'Enter' && !loginDisabled && actions.login({username, password})}
                       className="border rounded w-72 h-12 px-4 text-sm"
                       placeholder="Enter your username" autoComplete="off" autoFocus/>
                <input value={password} onChange={e => setPassword(e.target.value)} type="password"
                       onKeyDown={e => e.key === 'Enter' && !loginDisabled && actions.login({username, password})}
                       className="border rounded w-72 h-12 px-4  text-sm"
                       placeholder="Enter your password" autoComplete="off"/>
                <Link to="/forgot-password" className="text-center"><span className="text-sm cursor-pointer text-blue-600">Forgot password?</span></Link>
                {!state.login.processing &&
                    <button disabled={loginDisabled} onClick={() => actions.login({username, password})}
                            className={cn("border rounded", loginDisabled ?
                                "border-gray-100 py-3 bg-gray-200 text-gray-500" :
                                "border-blue-600 py-3 bg-blue-700 text-white hover:bg-white hover:text-blue-700")}>
                        <FontAwesomeIcon icon={faSignInAlt}/> Login
                    </button>}
                {state.login.processing && <div className="text-gray-600 text-center">
                    <FontAwesomeIcon icon={faSpinner}/> Logging in...
                </div>}
                {state.login.displayErrors && <div className="text-red-600 text-sm">
                    <FontAwesomeIcon icon={faExclamationTriangle}/> The provided credentials are invalid
                </div>}
            </div>
        </Layout>
    );
}

export default Login;
