import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {getSummaryReport, exportSummaryReport} from "redux/data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFileExport,
    faNoteSticky,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";


const SummaryReport = () => {
    const state = useSelector(({data: {summary}}) => summary);
    const dispatch = useDispatch();
    const actions = bindActionCreators({getSummaryReport, exportSummaryReport}, dispatch);

    // Local state
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [year, setYear] = useState('all');
    const [month, setMonth] = useState('all');
    const [toMonth, setToMonth] = useState('unset');

    // Effects
    useEffect(() => {
        actions.getSummaryReport({year, month, toMonth});
    }, [year, month, toMonth]);

    let total_reg_gas = 0, total_reg_elec = 0, total_com_gas = 0, total_com_elec = 0;

    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900"><FontAwesomeIcon icon={faNoteSticky}/> Registration / Completion
                Summary Report</h1>
            <div className="flex flex-row space-x-2">
                <p> Year/Month of Reports: </p>
                <select className="border px-2 py-1" value={year} onChange={e => setYear(e.target.value)}>
                    <option value="all">All Years</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                </select>
                <select className="border px-2 py-1" value={month} onChange={e => setMonth(e.target.value)}>
                    <option value="all">All Months</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
                <p> To Month Range: </p>
                <select className="border px-2 py-1" value={toMonth} onChange={e => setToMonth(e.target.value)}>
                    <option value="unset">Unset</option>
                    {1 > month &&<option value="1">January</option>}
                    {2 > month &&<option value="2">February</option>}
                    {3 > month &&<option value="3">March</option>}
                    {4 > month &&<option value="4">April</option>}
                    {5 > month &&<option value="5">May</option>}
                    {6 > month &&<option value="6">June</option>}
                    {7 > month &&<option value="7">July</option>}
                    {8 > month &&<option value="8">August</option>}
                    {9 > month &&<option value="9">September</option>}
                    {10 > month &&<option value="10">October</option>}
                    {11 > month &&<option value="11">November</option>}
                    {12 > month &&<option value="12">December</option>}
                </select>
            </div>
        </div>
        <div className="flex flex-row items-center justify-center">
            {!state.exporting && <button title="Export To Excel"
                                         onClick={() => actions.exportSummaryReport({year, month, toMonth})}
                                         className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                <FontAwesomeIcon icon={faFileExport}/> Export
            </button>}
            {state.exporting && <span className="text-xs">Exporting...</span>}
        </div>
        <table className="text-center h-full w-full">
            <thead className="block">
            <tr className="border-b flex flex-row font-bold text-sm">
                <td className="p-4 w-44">Jurisdiction</td>
                <td className="p-4 w-44">Gas Registered</td>
                <td className="p-4 w-44">Gas Completed</td>
                <td className="p-4 w-44">% Gas Completed</td>
                <td className="p-4 w-44">Electric Registered</td>
                <td className="p-4 w-44">Electric Completed</td>
                <td className="p-4 w-44">% Electric Completed</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {state.loading && <tr className="flex flex-row justify-center items-center text-2xl">
                <td>
                    <FontAwesomeIcon icon={faSpinner}/>
                </td>
            </tr>}

            {!state.loading && state.data.map((item, i) => {
                if (item != null) {
                    if (item.total_registered_gas != null) {
                        total_reg_gas += item.total_registered_gas;
                    }
                    if (item.total_completed_gas != null) {
                        total_com_gas += item.total_completed_gas;
                    }
                    if (item.total_registered_electric != null) {
                        total_reg_elec += item.total_registered_electric;
                    }
                    if (item.total_completed_electric != null) {
                        total_com_elec += item.total_completed_electric;
                    }

                    return <tr key={`sum-row-${i}`} className="flex flex-row border-b hover:bg-gray-200">
                        <td className="p-4 w-44 break-words">{item.jurisdiction}</td>
                        <td className="p-4 w-44 break-words">{item.total_registered_gas}</td>
                        <td className="p-4 w-44 break-words">{item.total_completed_gas}</td>
                        <td className="p-4 w-44 break-words">{(item.total_completed_gas * 100 / item.total_registered_gas).toFixed(2)}%</td>
                        <td className="p-4 w-44 break-words">{item.total_registered_electric}</td>
                        <td className="p-4 w-44 break-words">{item.total_completed_electric}</td>
                        <td className="p-4 w-44 break-words">{(item.total_completed_electric * 100 / item.total_registered_electric).toFixed(2)}%</td>
                    </tr>
                }
            })}
            {!state.loading && <tr className="flex flex-row border-b hover:bg-gray-200 font-bold">
                <td className="p-4 w-44 break-words">TOTAL</td>
                <td className="p-4 w-44 break-words">{total_reg_gas}</td>
                <td className="p-4 w-44 break-words">{total_com_gas}</td>
                <td className="p-4 w-44 break-words">{(total_com_gas * 100 / total_reg_gas).toFixed(2)}%</td>
                <td className="p-4 w-44 break-words">{total_reg_elec}</td>
                <td className="p-4 w-44 break-words">{total_com_elec}</td>
                <td className="p-4 w-44 break-words">{(total_com_elec * 100 / total_reg_elec).toFixed(2)}%</td>
            </tr>}
            </tbody>
        </table>
    </div>);
};

export default SummaryReport;
