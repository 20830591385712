import {combineReducers} from 'redux';
import storeConfig from 'config/store';
import rootSaga from 'saga';
import registration from './registration';
import users from './users';
import data from './data';

const appReducer = combineReducers({
    registration,
    users,
    data,
});

export default storeConfig(appReducer, rootSaga);
