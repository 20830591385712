import React, {useEffect} from 'react';
import Dialog from "elements/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {deleteUser, resetDeleteUser} from "redux/users";

const DeleteUserDialog = ({onClose, user, refetch}) => {
    // store & actions
    const state = useSelector(({users}) => users);
    const dispatch = useDispatch();
    const actions = bindActionCreators({deleteUser, resetDeleteUser}, dispatch);

    // effects
    useEffect(() => actions.resetDeleteUser, []);
    useEffect(() => {
        if (state.deleteUser.success) {
            refetch();
        }
    }, [state.deleteUser.success]);

    return (<Dialog onClose={onClose} width={500}>
        <div className="flex flex-col space-y-4 text-sm">
            <h3 className="text-gray-700">{`Are you sure you want to delete the user "${user.name}"`}?</h3>
            {state.deleteUser.error && !state.deleteUser.processing && <h3 className="text-red-700"><FontAwesomeIcon icon={faExclamationCircle}/> Error deleting the user, please try again later</h3>}
            {state.deleteUser.success && <h3 className="text-green-700"><FontAwesomeIcon icon={faCheckCircle}/> User deleted successfully</h3>}
            {state.deleteUser.processing && <div className="flex flex-row space-x-4 justify-end mr-10">Deleting...</div>}
            {!state.deleteUser.processing && !state.deleteUser.success && <div className="flex flex-row space-x-4 justify-end mr-10">
                <button onClick={onClose}
                        className="px-2 py-1 border border-gray-700 text-gray-700 hover:bg-gray-700 hover:text-gray-100">Cancel
                </button>
                <button onClick={() => actions.deleteUser({id: user.id})}
                        className="px-2 py-1 border border-red-700 text-red-700 hover:bg-red-700 hover:text-gray-100">Confirm
                </button>
            </div>}
        </div>

    </Dialog>);
}

export default DeleteUserDialog;
