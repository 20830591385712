import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Organization = ({data, loading}) => {

    const first = data[Object.keys(data)[0]] ?? [];
    console.log(first);

    return (<div className="h-full w-full overflow-x-scroll">
        {loading && <div className="flex flex-row justify-center text-4xl"><FontAwesomeIcon icon={faSpinner}/></div>}
        {!loading && <table className="text-center h-full w-full border-b">
            <tbody>
            <tr className="">
                <td rowSpan={2} className="p-4 w-38 border-r text-sm font-bold">Organization Type</td>
                {Object.keys(data).map(k =>
                    (<td key={`yer-hd-${k}`} className="font-bold text-lg justify-center text-center"
                         colSpan="6">{k}</td>)
                )}
            </tr>
            <tr className="border-b font-bold text-sm">
                {Object.keys(data).map(k =>
                    (<React.Fragment key={`yer-rep-${k}`}>
                        <td className="p-4 w-24">Gas Registered</td>
                        <td className="p-4 w-24">Gas Completed</td>
                        <td className="p-4 w-24">% Gas Completed</td>
                        <td className="p-4 w-24">Electric Registered</td>
                        <td className="p-4 w-24">Electric Completed</td>
                        <td className="p-4 w-24 border-r">% Electric Completed</td>
                    </React.Fragment>)
                )}
            </tr>
            {first.map((e, i) => (
                <tr key={`yer-d-row-${i}`}>
                    <td className="p-4 w-28 border-r">{e.organization_type}</td>
                    {Object.keys(data).map(k => {
                        const item = data[k][i];
                        const per_gas = (item.total_completed_gas * 100 / item.total_registered_gas).toFixed(2);
                        const per_elec = (item.total_completed_electric * 100 / item.total_registered_electric).toFixed(2);
                        return (<React.Fragment key={`yer-data-${k}`}>
                            <td className="p-4 w-28 break-words">{item.total_registered_gas}</td>
                            <td className="p-4 w-28 break-words">{item.total_completed_gas}</td>
                            <td className="p-4 w-28 break-words">%{isNaN(per_gas) ? 0.00 : per_gas}</td>
                            <td className="p-4 w-28 break-words">{item.total_registered_electric}</td>
                            <td className="p-4 w-28 break-words">{item.total_completed_electric}</td>
                            <td className="p-4 w-28 break-words border-r">%{isNaN(per_elec) ? 0.00 : per_elec}</td>
                        </React.Fragment>)
                    })}

                </tr>))}
            </tbody>
        </table>}
    </div>);
}

export default Organization;
