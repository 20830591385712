import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faMap} from "@fortawesome/free-solid-svg-icons";

const Jurisdiction = ({height = 700}) => {

    const [chartOne, setChartOne] = useState(2);
    const [chartTwo, setChartTwo] = useState(12);

    function chartLoad(one, two) {
        setChartOne(one);
        setChartTwo(two);
    }

    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faMap}/> Registration / Completion By Jurisdiction
            </h1>
        </div>
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 2} value={2} onChange={() => chartLoad(2, 12)}/>
                <span>NY</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 3} value={3} onChange={() => chartLoad(3, 13)}/>
                <span>MA / RI / Other</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 7} value={7} onChange={() => chartLoad(7, 17)}/>
                <span>NY (current year)</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 8} value={8} onChange={() => chartLoad(8, 18)}/>
                <span>MA / RI / Other (current year)</span>
            </div>
        </div>
        <div id="nyR" className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_Jurisdiction&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/>PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Registrations_Jurisdiction&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                    <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartOne}`} alt="chart"/>
            </div>
        </div>
        <br/><br/>
        <div id="nyC" className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_Jurisdiction&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartTwo}&filename=Completion_Jurisdiction&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartTwo}`} alt="chart"/>
            </div>
        </div>
    </div>);
};

export default Jurisdiction;
