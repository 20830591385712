import React, {useState} from 'react';
import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft, faCheckCircle, faExclamationTriangle, faInfoCircle, faSpinner, faSave
} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, Navigate, useLocation} from "react-router-dom";
import {resetPassword} from "redux/registration";
import Layout from "./layout";


const ResetPassword = () => {
    const state = useSelector(state => state.registration.resetPassword);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');

    const dispatch = useDispatch();
    const actions = bindActionCreators({resetPassword}, dispatch);

    const [pass, setPass] = useState('');
    const [passConfirm, setPassConfirm] = useState('');

    const resetPasswordDisabled = pass === '' || passConfirm === '' || pass.length < 6 || pass !== passConfirm;

    if (state.user) return <Navigate to="/" state={{from: location}}/>;

    const payload = {password: pass, password_confirmation: passConfirm, token, email};

    return (<Layout>
        <div className="flex flex-col space-y-4">
            <div className="text-gray-600">
                <FontAwesomeIcon icon={faInfoCircle}/> Create a new password
            </div>
            {state.response.status !== 'ok' && <>
                <span className="text-xs text-blue-900 font-bold">Ensure the new password is 6 characters or more</span>
                <input value={pass} onChange={e => setPass(e.target.value)} type="password"
                       onKeyDown={e => e.key === 'Enter' && !resetPasswordDisabled && actions.resetPassword(payload)}
                       className="border rounded w-72 h-12 px-4 text-sm"
                       placeholder="Enter your new password" autoComplete="off" autoFocus/>
                <input value={passConfirm} onChange={e => setPassConfirm(e.target.value)} type="password"
                       onKeyDown={e => e.key === 'Enter' && !resetPasswordDisabled && actions.resetPassword(payload)}
                       className="border rounded w-72 h-12 px-4 text-sm"
                       placeholder="Confirm your new password" autoComplete="off" autoFocus/>
                {!state.processing &&
                    <button disabled={resetPasswordDisabled} onClick={() => actions.resetPassword(payload)}
                            className={cn("border rounded", resetPasswordDisabled ? "border-gray-100 py-3 bg-gray-200 text-gray-500" : "border-blue-600 py-3 bg-blue-700 text-white hover:bg-white hover:text-blue-700")}>
                        <FontAwesomeIcon icon={faSave}/> Reset Password
                    </button>}
                {state.processing && <div className="text-gray-600 text-center">
                    <FontAwesomeIcon icon={faSpinner}/> Resetting Password...
                </div>}
                {state.response.status === 'error' && <div className="text-red-600 text-sm">
                    <FontAwesomeIcon icon={faExclamationTriangle}/> {state.response.msg}
                </div>}

            </>}
            {state.response.status === 'ok' && <>
                <div className="text-green-600 text-sm">
                    <FontAwesomeIcon icon={faCheckCircle}/> {state.response.msg}
                </div>
                <Link to="/login" className="text-center">
                    <span className="text-sm cursor-pointer text-blue-600">
                    <FontAwesomeIcon icon={faArrowLeft}/> Go to login
                    </span>
                </Link>
            </>}
        </div>
    </Layout>);
}

export default ResetPassword;
