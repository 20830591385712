import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import Login from "components/pages/login/login";
import ForgotPassword from "components/pages/login/forgot-password";
import ResetPassword from "components/pages/login/reset-password";
import Home from "components/pages/home";
import Notfound from "components/pages/notfound";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchUser} from "redux/registration";
import request from "util/http";
import {API_URL} from "config/constants";

const Router = () => {

    const state = useSelector(({registration}) => ({registration}));
    const dispatch = useDispatch();
    const actions = bindActionCreators({fetchUser}, dispatch)

    const [loading, setLoading] = useState(true);
    const [loadCsrf, setLoadCsrf] = useState(true);

    useEffect(() => {
        request('GET', API_URL + '/sanctum/csrf-cookie', [], []).then(() => {
            setLoadCsrf(false);
        });
    }, [])

    useEffect(() => {
        if (!loadCsrf)
            actions.fetchUser();
    }, [loadCsrf])

    useEffect(() => {
        if (state.registration.user !== null) {
            setLoading(false);
        }
    }, [state.registration.user]);

    if (loading)
        return (
            <div
                className="w-screen h-screen bg-gray-800 text-white text-6xl flex flex-col justify-center items-center">
                <FontAwesomeIcon icon={faSpinner}/>
            </div>
        );


    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<Login/>}/>
                <Route path="forgot-password" element={<ForgotPassword/>}/>
                <Route path="reset-password" element={<ResetPassword/>}/>
                <Route path="/404" element={<Notfound/>}/>
                <Route path="/*" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
