// Registration Store
export const FETCH_USER = 'registration/fetch_user';
export const FETCH_USER_SUCCESS = 'registration/fetch_user_success';
export const FETCH_USER_FAILURE = 'registration/fetch_user_failure';
export const LOGIN = 'registration/login';
export const LOGIN_SUCCESS = 'registration/login_success';
export const LOGIN_FAILURE = 'registration/login_failure';
export const FORGOT_PASSWORD = 'registration/forgot_password';
export const FORGOT_PASSWORD_FAILURE = 'registration/forgot_password_failure';
export const FORGOT_PASSWORD_SUCCESS = 'registration/forgot_password_success';
export const RESET_PASSWORD = 'registration/reset_password';
export const RESET_PASSWORD_FAILURE = 'registration/reset_password_failure';
export const RESET_PASSWORD_SUCCESS = 'registration/reset_password_success';
export const LOGOUT = 'registration/logout';
export const LOGOUT_SUCCESS = 'registration/logout_success';


// Initial State
const INITIAL_STATE = {
    user: null, login: {
        processing: false, displayErrors: false,
    }, forgotPassword: {
        processing: false, response: {},
    }, resetPassword: {
        processing: false, response: {},
    },
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOGIN:
            return {...state, login: {...state.login, processing: true, displayErrors: false}};
        case LOGIN_SUCCESS:
            return {...state, user: true, login: {processing: false}};
        case LOGIN_FAILURE:
            return {...state, user: false, login: {processing: false, displayErrors: true}};
        case FORGOT_PASSWORD:
            return {...state, forgotPassword: {processing: true, response: {}}};
        case FORGOT_PASSWORD_SUCCESS:
            return {...state, forgotPassword: {processing: false, response: action.payload}};
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state, forgotPassword: {
                    processing: false, response: {status: 'error', msg: 'Unexpected error, try again later'}
                }
            };
        case RESET_PASSWORD:
            return {...state, resetPassword: {processing: true, response: {}}};
        case RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: {processing: false, response: action.payload}};
        case RESET_PASSWORD_FAILURE:
            return {
                ...state, resetPassword: {
                    processing: false, response: {status: 'error', msg: 'Unexpected error, try again later'}
                }
            };
        case FETCH_USER_SUCCESS:
            return {...state, user: action.payload};
        case FETCH_USER_FAILURE:
            return {...state, user: false};
        case LOGOUT_SUCCESS:
            return {...state, user: null};
        default:
            return state;
    }
}

// Action Creators
export function login(payload) {
    return {type: LOGIN, payload};
}

export function fetchUser() {
    return {type: FETCH_USER};
}

export function logout() {
    return {type: LOGOUT};
}

export function forgotPassword(payload) {
    return {type: FORGOT_PASSWORD, payload};
}

export function resetPassword(payload) {
    return {type: RESET_PASSWORD, payload};
}
