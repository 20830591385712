import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";

const OrganizationType = ({height = 600}) => {

    const [chartOne, setChartOne] = useState(21);

    return (<div className="px-4 py-10">
        <div className="flex flex-row justify-between mb-10 mr-20">
            <h1 className="text-2xl text-blue-900">
                <FontAwesomeIcon icon={faPeopleGroup}/> Registration / Completion % By Population
            </h1>
        </div>
        <div className="flex flex-row items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 21} value={21} onChange={() => setChartOne(21)}/>
                <span>Jurisdiction</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 22} value={22} onChange={() => setChartOne(22)}/>
                <span>MA (By Job Title)</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 23} value={23} onChange={() => setChartOne(23)}/>
                <span>UNY (By Job Title)</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 24} value={24} onChange={() => setChartOne(24)}/>
                <span>DNY (By Job Title)</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
                <input type="radio" name="chart" checked={chartOne === 25} value={25} onChange={() => setChartOne(25)}/>
                <span>RI (By Job Title)</span>
            </div>
        </div>
        <div className="px-4 py-10">
            <div className="flex flex-row items-center justify-center space-x-4">
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Completion_POP&type=pdf`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PDF
                    </button>
                </a>
                <a href={`https://api.fr-reporting.ngridsafety.com/api/chart-export?id=${chartOne}&filename=Completion_POP&type=png`}>
                    <button className="text-blue-700 text-xs px-1 py-0.5 border border-blue-700 hover:text-white hover:bg-blue-700">
                        <FontAwesomeIcon icon={faDownload}/> PNG
                    </button>
                </a>
            </div>
            <div className="w-full overflow-x-scroll" style={{height}}>
                <img style={{height: "100%", width: "auto", maxWidth: "none"}} src={`https://api.fr-reporting.ngridsafety.com/api/chart?id=${chartOne}`} alt="chart"/>
            </div>
        </div>    </div>);
};

export default OrganizationType;
